import "./About.scss";

import React from "react";

export interface AboutProps {}

export const About = (props: AboutProps) => (
  <>
    <div className="container">
      <h2>
        Owing to the experience of working with startups and corporate clients,
        we're able to adjust quickly to the customer's needs and achieve
        measurable effects in a short period of time.
      </h2>
      <div className="features">
        <div className="feature">
          <ul>
            <div className="outer-circle red">
              <div className="inner-circle" />
            </div>

            <div className="feature_header">10 YEARS OF EXPERIENCE</div>

            <li>Startups</li>
            <li>Corporations</li>
            <li>Software agencies</li>
            <li>Product agencies</li>
          </ul>
        </div>

        <div className="feature">
          <ul>
            <div className="outer-circle grey">
              <div className="inner-circle" />
            </div>

            <div className="feature_header">MODELS OF COOPERATION</div>

            <li> Outsourcing</li>
            <li> Body leasing</li>
            <li> Team leasing</li>
            <li> Bespoke projects</li>
          </ul>
        </div>

        <div className="feature">
          <ul>
            <div className="outer-circle white">
              <div className="inner-circle" />
            </div>

            <div className="feature_header">TECHNOLOGIES</div>

            <li>
              <b>Cross-platform</b> (Flutter, React Native)
            </li>
            <li>
              <b>Android </b> (Kotlin)
            </li>
            <li>
              <b>iOS </b>(Objective C, Swift)
            </li>
            <li>
              <b>Web </b>(React.js, Jamstack, Django)
            </li>
            <li>
              <b>Backend</b> (Node.js, PHP)
            </li>
          </ul>
        </div>
      </div>
    </div>
  </>
);
