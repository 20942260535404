import { About } from "../components/About/About";
import { ContinueReading } from "../components/ContinueReading/ContinueReading";
import React from "react";
import { TestimonialSlider } from "../components/TestimonialSlider/TestimonialSlider";
import { WhyUs } from "../components/WhyUs/WhyUs";
import Seo from "../components/Seo/seo";
import { OurPartners } from "../components/OurPartners/OurPartners";

function HowWeWorkPage() {
  return (
    <>
      <Seo title={"How we work"} />
      <div className="sections">
        <section className="why-us">
          <WhyUs />
        </section>
        <section className="about">
          <About />
        </section>
        <section className="testimonial-slider hide-mobile">
          <TestimonialSlider page="how-we-work" />
        </section>
        <section className="our-partners">
          <OurPartners />
        </section>
        <section>
          <ContinueReading nextSection={"Portfolio"} link="portfolio" />
        </section>
      </div>
    </>
  );
}

export default HowWeWorkPage;
