import "./WhyUs.scss";
import "../../utils/slick.scss";
import "../../utils/slick-theme.scss";

import { ArrowButton, IDirection } from "../widgets/ArrowButton/ArrowButton";
import React, { useRef, useState } from "react";

import CollaborationIcon from "../../assets/Collaboration-icon.svg";
import GitlabIcon from "../../assets/Gitlab-icon.svg";
import HangoutsIcon from "../../assets/Hangouts-icon.svg";
import JiraIcon from "../../assets/Jira-icon.svg";
import ProcessIcon from "../../assets/Process-icon.svg";
import ProofTestingIcon from "../../assets/ProofTesting-icon.svg";
import ScalabilityIcon from "../../assets/Scalability-icon.svg";
import SlackIcon from "../../assets/Slack-icon.svg";
import Slider from "react-slick";
import TransparencyIcon from "../../assets/Transparency-icon.svg";
import TrialIcon from "../../assets/Trial-icon.svg";
import classNames from "classnames";
import { useWindowDimensions } from "../widgets/useWindowDimensions";
import Seo from "../Seo/seo";
import { Header } from "../Header/Header";

type SectionType = {
  title: JSX.Element;
  icon: JSX.Element;
  content: JSX.Element;
};

const content: SectionType[] = [
  {
    title: <>01&nbsp; Process</>,
    icon: <ProcessIcon />,
    content: (
      <>
        Successful project implementation requires efficient communication,
        clearly defined milestones and transparency. This is how we do it:
        <ul>
          <li>Grooming meetings to define requirements</li>
          <li>Sprint Planning to prioritize and clarify all ambiguities</li>
          <li>Demo presentations after each sprint</li>
          <li>Retrospective meetings with the project team</li>
          <li>Dedicated Single Point of Contact</li>
        </ul>
      </>
    ),
  },
  {
    title: <>02&nbsp; Transparency</>,
    icon: <TransparencyIcon />,
    content: (
      <>
        AllBright provides accurate time and cost estimates. We advise the
        technology solutions and set up an efficient release plan in line with
        your budget.
      </>
    ),
  },
  {
    title: <>03&nbsp; Scalability</>,
    icon: <ScalabilityIcon />,
    content: (
      <>
        Our solutions are scalable and development-standards compliant. We are
        thinking upfront to reduce your long-term costs.
      </>
    ),
  },
  {
    title: <>04&nbsp; Proof&nbsp;Testing</>,
    icon: <ProofTestingIcon />,
    content: (
      <>
        Our team develops through practice, by building prototypes, models and
        proofs of concepts. This is how we verify initial assumptions and review
        planned outcomes step by step.
      </>
    ),
  },
  {
    title: <>05&nbsp; Collaboration</>,
    icon: <CollaborationIcon />,
    content: (
      <>
        We use tools to help us manage projects and communicate with customers
        efficiently. Our tools include
        <div className="collaboration-icons">
          <JiraIcon />
          <SlackIcon />
          <GitlabIcon />
          <HangoutsIcon />
        </div>
      </>
    ),
  },
  {
    title: <>06&nbsp; Trial&nbsp;period</>,
    icon: <TrialIcon />,
    content: (
      <>
        We understand the importance of trust, reliability, professional
        approach and efficient communication in building successful
        partnerships. This is why our new business relations always start with a
        <b> 2-week trial period.</b>
      </>
    ),
  },
];

const SectionTab = (props: {
  index: number;
  title: string;
  selected(index: number): void;
  isActive: boolean;
}) => (
  <li
    className={classNames({ active: props.isActive })}
    onMouseEnter={(e) => props.selected(props.index)}
  >
    <div className="titles-menu-line">
      <div className="line" />
    </div>
    {props.title}
  </li>
);

const WhyUsSection = (props: {
  icon: JSX.Element;
  content: JSX.Element;
  title?: JSX.Element;
}) => (
  <div className="content">
    {props.title ? (
      <div className="slide-title-with-icon">
        <p>{props.icon}</p>
        <div className="slide-title">{props.title}</div>
      </div>
    ) : (
      <p>{props.icon}</p>
    )}
    <div className="text text--no-border">{props.content}</div>
  </div>
);

export const WhyUs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabHover = (index: number) => {
    setActiveTab(index);
  };

  const handleNextClick = () => {
    let nextTab = activeTab + 1;
    if (nextTab >= content.length) {
      nextTab = 0;
    }

    setActiveTab(nextTab);
  };

  const handlePreviousClick = () => {
    let previousTab = activeTab - 1;
    if (previousTab < 0) {
      previousTab = content.length - 1;
    }
    setActiveTab(previousTab);
  };

  var sections = content.map(function(section: any, index: number) {
    return (
      <SectionTab
        key={index}
        index={index}
        title={section.title}
        selected={handleTabHover}
        isActive={activeTab == index}
      />
    );
  });

  var currentSection = content[activeTab];

  return (
    <div className="container">
      <Header
        title="How we work"
        subTitle="Partnership and performance"
        subTitleNoBorderDesktop
        customElement={
          <div className="arrowButtonContainer">
            <ArrowButton
              direction={IDirection.Left}
              handleClick={handlePreviousClick}
            />
            <ArrowButton
              direction={IDirection.Right}
              handleClick={handleNextClick}
            />
          </div>
        }
      />

      <div className="features">
        <div className="left-panel">
          <div className="content">
            <ul className="why-us-menu">{sections}</ul>
          </div>
        </div>
        <div className="right-panel">
          <WhyUsSection
            icon={currentSection.icon}
            content={currentSection.content}
          />
        </div>
      </div>
    </div>
  );
};
